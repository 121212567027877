$flaticon_mycollection-font: "flaticon_mycollection";

@font-face {
    font-family: $flaticon_mycollection-font;
    src: url("./flaticon_mycollection.ttf?795ae5510f27894865a82b5c1fc3421a") format("truetype"),
url("./flaticon_mycollection.woff?795ae5510f27894865a82b5c1fc3421a") format("woff"),
url("./flaticon_mycollection.woff2?795ae5510f27894865a82b5c1fc3421a") format("woff2"),
url("./flaticon_mycollection.eot?795ae5510f27894865a82b5c1fc3421a#iefix") format("embedded-opentype"),
url("./flaticon_mycollection.svg?795ae5510f27894865a82b5c1fc3421a#flaticon_mycollection") format("svg");
}

i[class^="flaticon-"]:before, i[class*=" flaticon-"]:before {
    font-family: flaticon_mycollection !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$flaticon_mycollection-map: (
    "black-plane": "\f101",
    "two-plane": "\f102",
    "turn-down": "\f103",
    "right-down": "\f104",
);

.flaticon-black-plane:before {
    content: map-get($flaticon_mycollection-map, "black-plane");
}
.flaticon-two-plane:before {
    content: map-get($flaticon_mycollection-map, "two-plane");
}
.flaticon-turn-down:before {
    content: map-get($flaticon_mycollection-map, "turn-down");
}
.flaticon-right-down:before {
    content: map-get($flaticon_mycollection-map, "right-down");
}
