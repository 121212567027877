.DateInput_1 {
    width: 100%;
    height: 100%;
}

#nc__rj_ {
    height: 100%;
    width: 100%;
}

#nc__rk_ {
    height: 100%;
    width: 100%;
}

#your_unique_id {
    height: 60px;
}

.flight-search-from {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
    grid-template-rows: 80px;
}

.flight-search-from-round {
    display: grid;
    grid-template-columns: 3fr 3fr 4fr 2fr;
    grid-template-rows:80px 80px;
}

/* .date-range-picker:focus-within{
    position: absolute!important;
    transform: translateX(100%)!important;
} */

.flight-search-from-round .depart, .flight-search-from-round .return {
    width: 48%;
    display: inline-flex;
    flex-direction: row;
}

.flight-search-from-round .return {
    margin-left: 4%;
}

@media only screen and (max-device-width:430px) {
    @supports (-webkit-touch-callout: none) {

        .flight-search-from-round .depart {
            transform: translateY(-104%);
        }

        .flight-search-from-round .return {
            transform: translateY(-100%);
        }
    }
}

@media only screen and (max-device-width:430px) {
    .flight-search-from {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .flight-from-where {
        width: 100%;
        height: 60px;
    }
    .phone-container {
        width: 100%;
        height: 60px;
    }

    .to-where {
        width: 100%;
        height: 60px;
    }

    .date-container {
        width: 100%;
        margin: 5px;
        height: 60px;

    }

    .depart {
        width: 100%;
        height: 60px;
    }
    .search{
        width: 100%;
        height: 60px;
    }

    .return {
        width: 100%;
        height: 60px;
    }

    .guests {
        width: 100%;
        margin: 5px;
        height: 60px;
    }

}


@media only screen and (max-device-width:540px) {
    .flight-search-from {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .flight-from-where {
        width: 100%;
        height: 55px;
    }
    .phone-container {
        width: 100%;
        height: 55px;
    }

    .to-where {
        width: 100%;
        height: 55px;
    }

    .date-container {
        width: 47%;
        margin: 5px;
        height: 55px;
    }

    .depart {
        width: 100%;
        height: 55px;
    }
    .search{
        width: 100%;
        height: 55px;
    }

    .return {
        width: 100%;
        height: 55px;
    }

    .guests {
        width: 47%;
        margin: 5px;
        height: 55px;
    }

    .search {
        height: 50px;
    }
}

@media only screen and (max-device-width:1024px) {
    .flight-search-from {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 75px 75px 60px;
    }

    .flight-search-from-multi {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 75px 75px;
    }
}



@media only screen and (max-device-width:1134px) {
    .flight-search-from-round {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 80px 80px 80px;
    }

    .flight-search-from-round .depart, .flight-search-from-round .return {
        width: 49%;
    }

    .flight-search-from-round .return {
        margin-left: 2%;
    }
}

@media only screen and (max-device-width:642px) {
    .flight-search-from-round {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .flight-search-from-round .flight-from-where {
        width: 100%;
    }

    .flight-search-from-round .to-where {
        width: 100%;
    }
    .flight-search-from-round .phone-container  {
        width: 100%;
        height: 60px;

    }
    .flight-search-from-round .date-container {
        width: 100%;
        margin: 5px;
        height: 60px;
    }

    .flight-search-from-round .depart, .flight-search-from-round .return{
        width: 48%;
    }
    .flight-search-from-round .search{
        width: 100%;
    }

    .flight-search-from-round .return {
        margin-left: 1%;
    }

    .flight-search-from-round .depart,.flight-search-from-round .search {
        margin-right: 1%;
        margin-left: 1%;
    }

    .flight-search-from-round .guests {
        width: 100%;
        margin-right: 1%;
        height: 60px;
    }
    .search{
        width: 100%;
        height: 60px;
       
    }


}

@media only screen and (max-device-width:430px) {
    .flight-search-from {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .flight-from-where {
        width: 100%;
        height: 55px;
    }
    .phone-container {
        width: 100%;
        height: 55px;
    }

    .to-where {
        width: 100%;
        height: 55px;
    }

    .date-container {
        width: 100%;
        height: 55px;
    }


    .depart {
        width: 100%;
        height: 55px;
    }
    .search{
        width: 100%;
        height: 55px;
       
    }

    .return {
        width: 100%;
        height: 55px;
    }

    .guests {
        width: 100%;
        height: 55px;
    }

   
}
.search-btn::placeholder{
font-size: 17px;
font-weight: 400;
}
.search-btn{
    outline: 0 !important;
    border: none !important;
}
input.search-btn:focus{
    outline-width: 0 !important;
    outline: none !important;
    border: none !important;
    box-shadow: 0 0 0 rgb(255, 255, 255);

}

@media only screen and (max-width:690px){
    .search-btn::placeholder{
        font-size: 13px;
        font-weight: 400;
        }
}

