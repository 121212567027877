/* .flight-search-popup{
    background: rgba(0, 0, 0, 0.7);
} */
body {
  margin: 0;
  /* padding: 25px; */
}

.demo-container {
  width: 300px;
  margin: auto;
}

.progress-bar {
  height: 4px;
  background-color: rgb(224, 138, 137);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: #F70F36;
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform: translateX(0) scaleX(0);
  }

  40% {
    transform: translateX(0) scaleX(0.4);
  }

  100% {
    transform: translateX(100%) scaleX(0.5);
  }
}
.airplane-animation{
  animation: airplane 1.5s ease-in-out infinite;
}
@keyframes airplane {
  from{
    bottom:-50px;
  }
  to{
    bottom: 100%;
  }
}