.best-cont {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 200px 200px;
  row-gap: 30px;
  column-gap: 30px;
}
@media only screen and (max-width: 1400px) {
  .best-cont {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 200px 200px;
    row-gap: 30px;
    column-gap: 30px;
  }
}
@media only screen and (max-width: 1232px) {
  .best-cont {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 200px 200px;
    row-gap: 30px;
    column-gap: 30px;
  }
}
@media only screen and (max-width:980px) {
    .best-cont {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 200px 200px 200px ;
        row-gap: 30px;
    column-gap: 30px;
    }
}
@media only screen and (max-width: 660px) {
  .best-cont {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 200px 200px 200px;
    row-gap: 30px;
    column-gap: 30px;
  }
}
@media only screen and (max-width: 508px) {
  .best-cont {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 200px);
    row-gap: 30px;
    column-gap: 30px;
  }
}
@supports (-webkit-touch-callout: none) {
  @media only screen and (max-width: 200px) {
    .best-cont {
      display: flex !important;
      margin: 10px !important;
      flex-direction: column !important;
      justify-items: center !important;
      align-items: center !important;
    }
  }
}
@media only screen and (max-width: 380px) {
  .best-cont {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 200px);
    row-gap: 30px;
    column-gap: 30px;
  }
}
@supports (-webkit-touch-callout: none) {
  @media only screen and (max-width: 440px) {
    .best-cont {
      display: flex !important;
      margin: 10px !important;
      flex-direction: column !important;
      justify-items: center !important;
      align-items: center !important;
    }
  }
}