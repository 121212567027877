.DateInput_1 {
    width: 100%;
    height: 100%;
}

#nc__rj_ {
    height: 100%;
    width: 100%;
}

#nc__rk_ {
    height: 100%;
    width: 100%;
}

#your_unique_id {
    height: 60px;
}

.hero-form {
    display: grid;
    grid-template-columns: 3fr 3fr 2fr 2fr 2fr;
    grid-template-rows: 80px;
}

.hero-form-round {
    display: grid;
    grid-template-columns: 3fr 3fr 4fr 2fr 2fr;
    grid-template-rows: 80px;
}

/* .date-range-picker:focus-within{
    position: absolute!important;
    transform: translateX(100%)!important;
} */

.hero-form-round .depart, .hero-form-round .return {
    width: 48%;
    display: inline-flex;
    flex-direction: row;
}

.hero-form-round .return {
    margin-left: 4%;
}

@media only screen and (max-device-width:430px) {
    @supports (-webkit-touch-callout: none) {

        /* CSS specific to iOS devices */
        .hero-form-round .depart {
            transform: translateY(-104%);
        }

        .hero-form-round .return {
            transform: translateY(-100%);
        }
    }
}

@media only screen and (max-device-width:430px) {
    .hero-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .from-where {
        width: 100%;
        height: 60px;
    }

    .to-where {
        width: 100%;
        height: 60px;
    }

    .date-container {
        width: 100%;
        margin: 5px;
        height: 60px;

    }

    .depart {
        width: 100%;
        height: 60px;
    }

    .return {
        width: 100%;
        height: 60px;
    }

    .guests {
        width: 100%;
        margin: 5px;
        height: 60px;
    }

    .search {
        height: 55px;
    }

    /* @supports(-webkit-touch-callout:none) {
        .hero-form {
            display: flex !important;
            flex-wrap: wrap !important;
            justify-content: center !important;
        }

        .from-where {
            width: 100% !important;
            height: 60px !important;
        }

        .to-where {
            width: 100% !important;
            height: 60px !important;
        }

        .date-container {
            width: 100% !important;
            margin: 5px !important;
            height: 60px !important;

        }

        .depart {
            width: 100% !important;
            height: 60px !important;
        }

        .return {
            width: 100% !important;
            height: 60px !important;
        }

        .guests {
            width: 100% !important;
            margin: 5px !important;
            height: 60px !important;
        }

        .search {
            height: 55px !important;
        }
    } */
}


@media only screen and (max-device-width:540px) {
    .hero-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .from-where {
        width: 100%;
        height: 55px;
    }

    .to-where {
        width: 100%;
        height: 55px;
    }

    .date-container {
        width: 47%;
        margin: 5px;
        height: 55px;
    }

    .depart {
        width: 100%;
        height: 55px;
    }

    .return {
        width: 100%;
        height: 55px;
    }

    .guests {
        width: 47%;
        margin: 5px;
        height: 55px;
    }

    .search {
        height: 50px;
    }
}

@media only screen and (max-device-width:1024px) {
    .hero-form {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 75px 75px 60px;
    }

    .hero-form-multi {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 75px 75px;
    }
}



@media only screen and (max-device-width:1134px) {
    .hero-form-round {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 80px 80px 65px;
    }

    .hero-form-round .depart, .hero-form-round .return {
        width: 49%;
    }

    .hero-form-round .return {
        margin-left: 2%;
    }
}

@media only screen and (max-device-width:642px) {
    .hero-form-round {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .hero-form-round .from-where {
        width: 100%;
    }

    .hero-form-round .to-where {
        width: 100%;
    }

    .hero-form-round .date-container {
        width: 100%;
        margin: 5px;
        height: 60px;
    }

    .hero-form-round .depart, .hero-form-round .return {
        width: 48%;
    }

    .hero-form-round .return {
        margin-left: 1%;
    }

    .hero-form-round .depart {
        margin-right: 1%;
        margin-left: 1%;
    }

    .hero-form-round .guests {
        width: 47%;
        margin-right: 1%;
        height: 60px;
    }

    .hero-form-round .search {
        width: 47%;
        height: 60px;
        margin-left: 1%;
    }
}

@media only screen and (max-device-width:430px) {
    .hero-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .from-where {
        width: 100%;
        height: 55px;
    }

    .to-where {
        width: 100%;
        height: 55px;
    }

    .date-container {
        width: 100%;
        height: 55px;
    }

    .depart {
        width: 100%;
        height: 55px;
    }

    .return {
        width: 100%;
        height: 55px;
    }

    .guests {
        width: 100%;
        height: 55px;
    }

    .search {
        height: 50px;
        width: 100%;
    }
}