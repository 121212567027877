@font-face {
  font-family: "flaticon_mycollection";
  src: url("./flaticon_mycollection.ttf?795ae5510f27894865a82b5c1fc3421a") format("truetype"), url("./flaticon_mycollection.woff?795ae5510f27894865a82b5c1fc3421a") format("woff"), url("./flaticon_mycollection.woff2?795ae5510f27894865a82b5c1fc3421a") format("woff2"), url("./flaticon_mycollection.eot?795ae5510f27894865a82b5c1fc3421a#iefix") format("embedded-opentype"), url("./flaticon_mycollection.svg?795ae5510f27894865a82b5c1fc3421a#flaticon_mycollection") format("svg");
}
i[class^=flaticon-]:before, i[class*=" flaticon-"]:before {
  font-family: flaticon_mycollection !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.flaticon-black-plane:before {
  content: "\f101";
}

.flaticon-two-plane:before {
  content: "\f102";
}

.flaticon-turn-down:before {
  content: "\f103";
}

.flaticon-right-down:before {
  content: "\f104";
}/*# sourceMappingURL=flaticon_mycollection.css.map */